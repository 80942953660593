import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bio from "../components/bio";
import {rhythm} from "../utils/typography";

export default class UsesPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Uses" />
        <h1>Uses</h1>
        <p>Here's some of my setups I use while coding.</p>
        <h2>Editor</h2>
        <ul>
          <li>I'm mostly using <a href="https://www.jetbrains.com/phpstorm/">PhpStorm from JetBrains</a>.</li>
          <li>For quick editing tasks I also use <a href="https://code.visualstudio.com/">Visual Studio Code</a>.</li>
        </ul>
        <h2>Terminal + Shell</h2>
        <ul>
          <li>Most of the time I'm using the Terminal on MacOS, also trying out <a href="https://hyper.is/">Hyper</a>.</li>
          <li>I recently switched my shell to zsh and <a href="https://ohmyz.sh">Oh My ZSH!</a> with the <a href="https://github.com/denysdovhan/spaceship-prompt">Spaceship theme</a>.</li>
        </ul>
        <h2>Desk Setup</h2>
        <ul>
          <li>I'm using a Yaasa Desk Pro standing desk to be able to switch between sitting and standing frequently throughout the day.</li>
          <li>My current laptop is a 2017 15″ MacBook Pro 2.8GHz i7 with 16gb of lightning fast LPDDR3.</li>
        </ul>
        <hr
          style={{
            marginBottom: rhythm(1),
          }}
        />
        <footer>
          <Bio />
        </footer>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;